<template>
  <window-content v-if="!preventBind" class="financeiro-window">
    <div class="w-m-header">
      <pessoa-cadastro ref="cadastro" :mount-body="true" style="z-index: 11000" />
      <div>
        <h2 v-if="registro && registro.id">Registro #{{registro.id}}</h2>
        <h2 v-else>Novo Registro</h2>
      </div>
      <button @click="$uloc.window.close($root.wid)" type="button" class="btn-close">
        <i class="fal fa-times"/>
        <u-tooltip content-class="bg-indigo" :offset="[5, 5]">Fechar</u-tooltip>
      </button>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1 fin-form">
      <div class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Detalhes do pedido</legend>
          </e-col>
          <e-col v-if="registro.id" class="flex items-center justify-end m-r" style="max-width: 220px; min-height: 32px">
            ID #{{registro.id}}
          </e-col>
          <e-col class="flex items-center justify-end" style="max-width: 320px; min-height: 32px">
            <status-select size="2" label="Status:" placeholder="Selecione" field-view="lr" simple-border=""
                           v-model="registro.status"/>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field label="CPF Titular">
              <erp-input v-model="registro.documentoTitular" v-mask="'###.###.###-##'" @blur="findPessoaByDocument" autofocus />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="E-mail Nic.br">
              <erp-input v-model="registro.emailTitular" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Ticket">
              <erp-input v-model="registro.ticket" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row>
          <e-col>
            <e-row mr>
              <e-col>
                <erp-s-field label="Cliente">
                  <person-select v-model="registro.solicitante"/>
                  <div class="font-12 m-t-xs" v-if="registro.solicitante"><a @click="gotoPerson(registro.solicitante)">Visualizar pessoa</a></div>
                  <div class="font-12 m-t-xs" v-if="leiloeiro"><a @click="arrematanteWindow(leiloeiro.id)">Visualizar cadastro leiloeiro</a></div>
                  <div class="font-12 m-t-xs" v-if="registro.solicitante"><a @click="editPessoa(registro.solicitante.id)">Editar pessoa</a></div>
                  <div class="m-t-xs text-right font-11" v-else><a @click="$refs.cadastro.show()">Cadastrar pessoa</a></div>
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field required label="Domínio">
                  <erp-input placeholder="Nome do domínio" v-model="registro.dominio" required />
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field label="Observações internas">
                  <textarea placeholder="Observações sobre o cliente, registro ou alguma informação relevante" v-model="registro.observacao" class="sl-textarea"
                            rows="8"/>
                </erp-s-field>
              </e-col>
            </e-row>
          </e-col>
        </e-row>
      </div>
      <div v-if="leiloeiro" class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Documentos</legend>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <arrematante-documentos :arrematante="leiloeiro" :registro="registro" />
          </e-col>
        </e-row>
      </div>
      <div class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Detalhes do registro</legend>
          </e-col>
        </e-row>
        <e-row>
          <e-col>
            <e-row mr>
              <e-col style="max-width: 200px">
                <erp-s-field label="Data do pedido">
                  <erp-input v-model="registro.dataRegistro" v-mask="'##/##/####'" />
                </erp-s-field>
              </e-col>
              <e-col style="max-width: 200px">
                <erp-s-field label="Data expiração do registro">
                  <erp-input v-model="registro.dataExpiracao" v-mask="'##/##/####'" />
                </erp-s-field>
              </e-col>
              <e-col style="max-width: 200px">
                <erp-s-field label="Custo do Registro">
                  <erp-input v-model="registro.valorRegistro" v-money="money" />
                </erp-s-field>
              </e-col>
              <e-col style="max-width: 200px">
                <erp-s-field label="Custo do Serviço">
                  <erp-input v-model="registro.valorServico" v-money="money" />
                </erp-s-field>
              </e-col>
            </e-row>
          </e-col>
        </e-row>
      </div>
    </div>
    <div class="w-m-footer text-right footer-modern window-actions">
      <div class="text-left">
        <u-btn @click="$uloc.window.close($root.wid)" label="Voltar"
               class="bg-blue-grey-1 text-blue" no-caps/>
      </div>
      <div class="text-right">
        <u-btn :loading="loading" type="button" @click="save('andClose')" label="Salvar Registro" no-caps
               :color="'green'"
               class="b-radius-3px no-border-radius-right__"/>
        <!--        <u-btn icon="sort-down" icon-type="fa" no-caps class="p-btn-down normal b-radius-3px font-12 text-white">
                  <u-popover ref="popover" class="window-context-menu" style="width: 192px" anchor="bottom right"
                             self="top right" :offset="[0, 0]">
                    <div>
                      <ul>
                        <menu-options-item @click="save('andEdit')" label="Salvar e continuar editando" close/>
                        <menu-options-item @click="save('andNew')" label="Salvar e criar novo" close/>
                      </ul>
                    </div>
                  </u-popover>
                </u-btn>-->
      </div>
    </div>
  </window-content>
</template>

<script>
import {UTooltip, UPopover, debounce} from "uloc-vue"
import ERow from '@/components/layout/components/Row'
import {ErpInput, ErpSField, ErpSelect, WindowContent} from 'uloc-vue-plugin-erp'
import ECol from '@/components/layout/components/Col'
import {newDominio, updateDominio, find} from "@/domain/fenaju/services"
import {convertRealToMoney, REAL_BRL} from "@/utils/money"
import {VMoney} from "v-money"
// import SlCheckbox from "@/reuse/input/Checkbox"
import PersonSelect from "components/suporte/components/projetos/include/PersonSelect"
import StatusSelect from "components/fenaju/components/clientes/include/StatusSelect.vue"
import PlanoSelect from "components/clientes/components/cliente/include/PlanoSelect"
import ServidorSelect from "components/clientes/components/cliente/include/ServidorSelect"
import PessoaCadastro from '@/components/pessoa/components/window/Cadastro'
import {REPETIR} from "@/domain/gerencia/clientes/helpers/periodicidade"
import {datePtToEn} from "@/utils/date"
import Collapse from "components/globalconfig/components/include/Collapse";
import GlobalPersonMixin from "components/pessoa/globalPersonMixin"
import {findByDoc, findByPessoa} from "@/domain/arrematante/services"
import ArrematanteDocumentos from "components/arrematante/components/include/Documentos.vue";
import arrematanteWindow from "components/arrematante/windows/arrematante";

let mock = {
  id: null,
  dominio: null,
  ticket: null,
  documentoTitular: null,
  emailTitular: null,
  solicitante: null,
  observacao: null,
  dataRegistro: null,
  dataExpiracao: null,
  valorRegistro: null,
  valorServico: '150,00',
  status: 2,
  active: true
}

export default {
  name: 'RegistroDominioWindow',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [GlobalPersonMixin],
  directives: {money: VMoney},
  components: {
    ArrematanteDocumentos,
    PessoaCadastro,
    PersonSelect,
    // SlCheckbox,
    // DatetimeInput,
    ErpInput,
    ErpSField,
    ERow,
    ECol,
    // ErpSelect,
    UTooltip,
    //MenuOptionsItem,
    // UPopover,
    WindowContent,
    StatusSelect,
  },
  props: ['id', 'options', 'router'],
  data() {
    return {
      money: REAL_BRL,
      percent: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      },
      loading: false,
      editing: false,
      success: false,
      status: '',
      preventBind: true,
      registro: JSON.parse(JSON.stringify(mock)),
      leiloeiro: null
    }
  },
  mounted() {
    if (this.id) {
      this.load()
    } else {
      this.preventBind = false
    }
  },
  watch: {
    'registro.solicitante' (v) {
      if (v && v.document && this.$options.filters.somenteNumeros(v.document) !== this.$options.filters.somenteNumeros(this.registro.documentoTitular)) {
        this.registro.documentoTitular = this.$options.filters.formataCpf(v.document)
      }
      if (!this.registro.emailTitular) {
        if (v && v.emails && v.emails.length) {
          // this.registro.emailTitular = v.emails[0].email
        }
      }
    }
  },
  computed: {
  },
  methods: {
    load() {
      this.loading = true
      find(this.id)
          .then(response => {
            console.log(response)
            if (response.data.id) {
              this.registro.id = response.data.id
            }
            this.preventBind = true
            this.registro = Object.assign({}, this.registro, this.prepareToEdit(response.data))
            this.$nextTick(() => {
              setTimeout(() => {
                this.preventBind = false
              }, 1)
            })
            console.log(this.registro)
            if (this.registro.solicitante) {
              this.findLeiloeiroByPessoa(this.registro.solicitante.id)
            }
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    prepareToSave() {
      const data = JSON.parse(JSON.stringify(this.registro))
      const valores = ['valorRegistro', 'valorServico']
      valores.map(key => {
        data[key] = convertRealToMoney(data[key])
        if (!data[key]) {
          data[key] = null
        }
      })

      const relacoes = ['solicitante']
      relacoes.map(key => {
        if (data[key] && data[key].id) {
          data[key] = data[key].id
        }
      })

      const datas = ['dataRegistro', 'dataExpiracao']
      datas.map(key => {
        if (data[key]) {
          data[key] = datePtToEn(data[key])
        }
      })
      /*try {
        //data.configuracao = this.$refs.configEditor.innerText
        data.configuracao = JSON.stringify(data.configuracao)
      } catch (e) {
        alert('Configurações inválida')
        return
      }*/
      return data
    },
    prepareToEdit(data) {
      const valores = ['valorRegistro', 'valorServico']
      valores.map(key => {
        data[key] = this.$options.filters.moeda(data[key])
        if (!data[key]) {
          data[key] = null
        }
      })
      /*const ids = ['plano', 'servidor']
      ids.map(key => {
        if (data[key] && data[key].id) {
          data[key] = data[key].id
        }
      })*/
      const datas = ['dataRegistro', 'dataExpiracao']
      datas.map(key => {
        if (data[key] && data[key].date) {
          data[key] = this.$options.filters.formatDate(data[key].date)
        }
      })

      return data
    },
    async save(type = 'andClose') {
      this.loading = true
      let data
      try {
        data = this.prepareToSave()
      } catch (e) {
        console.log(e)
        this.alertApiError(null, 'Erro de validação', null, `${e.message} Código L-${e.lineNumber}`)
        this.loading = false
        return
      }
      const submit = () => {
        let method = newDominio
        if (this.registro.id) {
          method = (data) => updateDominio(data.id, data)
        }
        method(data)
            .then(response => {
              this.loading = false
              console.log(response)
              const isEditing = this.isEditing
              this.registro.id = response.data.id
              // this.$uloc.window.close(this.$root.wid)
              if (this.id) {
                this.$uloc.window.emit(this.$root.wid, 'updated', response.data)
                this.success = true
                this.dg()
                this.$uloc.notify({
                  color: 'positive',
                  message: `Registro com ID #${response.data.id} alterado.`,
                  position: 'bottom-left',
                  /*actions: [{
                    label: 'Visualizar',
                    handler: () => {
                      this.gotoPerson(response.data)
                    }
                  }]*/
                })
              } else {
                this.$uloc.window.emit(this.$root.wid, 'created', response.data)
                this.$uloc.notify({
                  color: 'black',
                  message: `Registro com ID #${response.data.id} ${this.id ? 'modificado' : 'criado'}.`,
                  position: 'bottom-left',
                  /*actions: [{
                    label: 'Visualizar',
                    handler: () => {
                      this.gotoPerson(response.data)
                    }
                  }]*/
                })
              }
              if ((!this.$refs.uploader || (this.$refs.uploader && !this.$refs.uploader.existsFilesToUpload)) && (type === 'andClose' || (this.entidade && this.entidadeId))) {
                // this.$uloc.window.close(this.$root.wid)
              } else if (type === 'andNew') {
                this.preventBind = true
                this.registro = JSON.parse(JSON.stringify(mock))
                this.$nextTick(() => {
                  this.preventBind = false
                })
              }
            })
            .catch(error => {
              console.log(error)
              this.loading = false
              this.alertApiError(error)
            })
      }
      submit()
    },
    editPessoa(id) {
      this.$refs.cadastro.show(id)
    },
    findPessoaByDocument () {
      const doc = this.registro.documentoTitular
      if (!doc) return
      if (this.registro && this.registro.solicitante && this.registro.solicitante.document && this.$options.filters.somenteNumeros(this.registro.solicitante.document) === this.$options.filters.somenteNumeros(doc)) {
        return
      }
      this.$uloc.loading.show()
      findByDoc(doc)
          .then(response => {
            this.$uloc.loading.hide()
            console.log(response.data)
            this.registro.solicitante = response.data.pessoa
          })
          .catch(error => {
            this.$uloc.loading.hide()
            console.log(error)
          })
    },
    findLeiloeiroByPessoa (id) {
      findByPessoa(id, this.registro?.id)
          .then(response => {
            this.leiloeiro = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },
    arrematanteWindow
  }
}
</script>
